/**
 * Active A/B testing experiments.
 */
export enum Experiment {
  summary_page_copy_relaunch_4113 = 'summary_page_copy_relaunch_4113',
  design_v4_4359 = 'design_v4_4359',
  ai_label_13537 = 'ai_label_13537',
  // Used just as a feature flag. No activation of the experiment on the backend.
  google_billing_7517 = 'google_billing_7517',
  // Experiment to be only used in tests
  test_experiment = 'test_experiment',
  backlink_8567 = 'backlink_8567',
  new_copy_pdf_v2_13448 = 'new_copy_pdf_v2_13448',
  ai_cover_flow_13860 = 'ai_cover_flow_13860',
  ai_guide_flow_13747 = 'ai_guide_flow_13747',
  long_onboarding_15290 = 'long_onboarding_15290',
  allow_copy_reading_page_14493 = 'allow_copy_reading_page_14493',
  bookmarks_14994 = 'bookmarks_14994',
  home_redesign_15080 = 'home_redesign_15080',
  stripe_payment_options_15213 = 'stripe_payment_options_15213',
  billing_redesign_15255 = 'billing_redesign_15255',
  keep_alive_listing_pages_12901 = 'keep_alive_listing_pages_12901',
}

/**
 * Hint for the checkResponse method.
 */
export enum CheckResponseHint {
  register = 'register',
  login = 'login',
  passwordReset = 'passwordReset',
  abStartError = 'abStartError',
}

/**
 * A type definition for the dictionary of experiments and assigned variants:
 * {
 *      experiment_X: 'variant1',
 *      site_page_1208: 'variant2',
 *  }
 */
export type Experiments = { [Key in Experiment]: string | null }

/**
 * A/B experiment and assigned variant data returned by the backend.
 */
export interface ABVariantData {
  experiment: Experiment
  variant: string
  client_id: string
}

export type ExperimentsDict = { [experiment: string]: string }

/**
 * A/B experiments data - client id and all active experiments for the user.
 */
export interface ABExperimentsData {
  client_id: string | null
  experiments: ExperimentsDict
}

/**
 * The backend response can contain a side-loaded
 * A/B testing information:
 * {
 *   "data": { ... regular response ... },
 *   "ab": { ... experiments / assigned variants data ... }
 *  }
 *  This way we can start experiments without sending additional
 *  API request to /ab/start endpoint.
 */
export interface ABSideloadedResponse {
  ab: ABExperimentsData
}

/**
 * EventData is the general A/B testing event information.
 *
 * We include current `url`, the `referrer` we came from,
 * client's `platform` and `os`.
 *
 * The `client_id` is optional, we may or may not have set
 * it yet on the client side.
 *
 * The `experiments` is an optional array of the A/B testing
 * experiments already active on the client.
 */
export type EventData = {
  client_id: string | null
  url: string
  referrer: string
  platform: string
  os: string
  experiments?: Experiments
}

/**
 * StartEvent represents the data for the /ab/start call.
 *
 * Besides the general data about the event from `EventData`,
 * we also have `experiment` here - the experiment to start.
 *
 * There is no `event` (it is set to "start" on the server)
 * and no `variant` (it is not assigned yet, server will return it).
 */
export interface StartEvent extends EventData {
  experiment: string
  variant: string | null
}

/**
 * ConvertEvent represents the data for the /ab/event call.
 *
 * We may have many conversion events for the same experiment.
 * The `event` is the event name ('signup', 'card save', etc).
 *
 * The `variant` is the value that was assigned to the client
 * previously and `experiment` is the experiment name we send
 * the conversion `event` for.
 */
export interface ConvertEvent extends EventData {
  event: string
  variant: string
  experiment: string
}

export type OnboardingQuestionnaireVariant = 'A' | 'B'
export type HomeRedesignVariant = 'A' | 'B' | 'C' | 'D'
export type BillingRedesignVariant = 'A' | 'B' | 'C' | null
