import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EarlyAccessBannerBook = _resolveComponent("EarlyAccessBannerBook")!
  const _component_EarlyAccessBannerPodcast = _resolveComponent("EarlyAccessBannerPodcast")!
  const _component_Chapter = _resolveComponent("Chapter")!
  const _component_Exercise = _resolveComponent("Exercise")!
  const _component_PrevNext = _resolveComponent("PrevNext")!
  const _component_AudioPlayer = _resolveComponent("AudioPlayer")!
  const _component_AudioPlayerDisabled = _resolveComponent("AudioPlayerDisabled")!
  const _component_DocLayout = _resolveComponent("DocLayout")!

  return (_openBlock(), _createBlock(_component_DocLayout, { doc: _ctx.doc }, _createSlots({
    "page-content": _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["book-reading__content-editor content-editor", _ctx.doc.settings.cssClass]),
        style: _normalizeStyle({ 'font-family': _ctx.doc.settings.fontFamily }),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onDocContentClick && _ctx.onDocContentClick(...args)))
      }, [
        (_ctx.doc.isCurrentPageChapter)
          ? (_openBlock(), _createBlock(_component_Chapter, {
              key: 0,
              content: _ctx.doc.currentPage,
              class: "chapter-text"
            }, null, 8, ["content"]))
          : (_ctx.doc.isCurrentPageOpenQuestions)
            ? (_openBlock(), _createBlock(_component_Exercise, {
                key: 1,
                content: _ctx.doc.currentExercise,
                isEOC: _ctx.doc.currentExercise.currentExercise.isExerciseEOC,
                class: "chapter-exercise"
              }, null, 8, ["content", "isEOC"]))
            : _createCommentVNode("", true)
      ], 6),
      _createVNode(_component_PrevNext, { doc: _ctx.doc }, null, 8, ["doc"])
    ]),
    "page-footer": _withCtx(() => [
      (_ctx.doc.audio && _ctx.doc.controls.isPlayerRendered)
        ? (_openBlock(), _createBlock(_component_AudioPlayer, {
            key: 0,
            doc: _ctx.doc
          }, null, 8, ["doc"]))
        : _createCommentVNode("", true),
      (!_ctx.doc.audio && _ctx.doc.has_audio && _ctx.doc.controls.isPlayerRendered)
        ? (_openBlock(), _createBlock(_component_AudioPlayerDisabled, {
            key: 1,
            class: _normalizeClass(_ctx.doc.controls.playerClass)
          }, null, 8, ["class"]))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.doc.showEarlyAccessBanner)
      ? {
          name: "banner",
          fn: _withCtx(() => [
            (_ctx.showBookBanner)
              ? (_openBlock(), _createBlock(_component_EarlyAccessBannerBook, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.doc.doc_type === 'podcast_episode')
              ? (_openBlock(), _createBlock(_component_EarlyAccessBannerPodcast, { key: 1 }))
              : _createCommentVNode("", true)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["doc"]))
}